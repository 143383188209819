<template>
    <!-- eslint-disable -->
    <div class="projectIntro">
        <van-nav-bar>
            <template #title>
                <p class="vocation_name">{{vocationInfo.vocation_name}}</p>
            </template>
            <template #left>
                <span @click="backHome">
                    <svg-icon icon-class="back-icon" class-name="icon" ></svg-icon>
                </span>
                <span @click="backHome">
			        <svg-icon icon-class="icon-home" class-name="icon icon-home" ></svg-icon>
                </span>
            </template>
        </van-nav-bar>
        <div class="search-box">
            <van-search v-model="goodsListParams.name" @blur="searchBtn" placeholder="搜索" />
        </div>
        <div class="filtrate-box">
             <van-dropdown-menu active-color="#316FFF">
                <van-dropdown-item @change="teacherWorkTypeClick" v-model="teacherWorkTypeIndex" v-if="$route.query.spec=='teacher'" :options="teacherWorkTypeList" />
                <van-dropdown-item @change="teacherSkillLevelClick" v-model="teacherSkillLevelIndex" :options="teacherSkillLevelList"  v-if="$route.query.spec=='teacher'"/>
                <van-dropdown-item @change="workTypeClick" v-model="workTypeIndex" v-if="$route.query.spec!='teacher'" :options="workTypeList" />
                <van-dropdown-item @change="skillLevelClick" v-model="skillLevelIndex" :options="skillLevelList"  v-if="$route.query.spec!='teacher'"/>
                <van-dropdown-item @change="trainMethodClick" v-model="trainMethodIndex" :options="trainMethodList"  v-if="$route.query.spec!='teacher'"/>
                <van-dropdown-item @change="sortClick" v-model="sortIndex" :options="sortList" v-if="token"  />
            </van-dropdown-menu>
        </div>
        <div class="training-box">
            <div class="content">
                <h2 class="name">{{vocationInfo.vocation_name}}</h2>
                <p class="info">{{vocationInfo.vocation_intro}}</p>
                <p class="button" @click="checkVocation" v-if="$route.query.spec!='teacher'">查看职业及工种</p>
            </div>
        </div>

        <div class="goodsList">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text=" 一 已经到底了，更多内容即将上线 一 "
                :immediate-check="false"
                ref="list"
                @load="onLoad"
                v-if="goodsList && goodsList.length"
            >
                <div class="item" v-for="item in goodsList" :key="item.id" @click="goGoodsDetail(item)">
                    <div class="top">
                        <div class="top-left">
                            <p class="trainMethod" v-if="item.trainingMethodName">{{item.trainingMethodName}}</p>
                            <img :src="item.pic" class="pic" alt="">
                        </div>
                        <div class="top-right">
                            <div class="title">
                                <p class="name">{{item.name}}</p>    
                                <p class="schoolHour" v-if="item.schoolHour"><span>{{item.schoolHour}}</span>课时</p>
                            </div>
                            <div class="time-box">
                                <p class="trainingTime time" v-if="item.cycleType == 1">培训周期：{{item.trainingStartTime && item.trainingStartTime.split(' ')[0].replace(/-/g, '.')}}-{{item.trainingEndTime && item.trainingEndTime.split(' ')[0].replace(/-/g, '.')}}</p>
                                <p class="trainingTime time" v-if="item.cycleType == 2">培训周期：随到随学</p>
                                <p class="trainingTime time" v-if="item.cycleType == 3">培训周期：长期有效</p>
                                <p class="signUpTime time" v-if="item.signUpStartTime">报名周期：{{item.signUpStartTime && item.signUpStartTime.split(' ')[0].replace(/-/g, '.')}}-{{item.signUpEndTime && item.signUpEndTime.split(' ')[0].replace(/-/g, '.')}}</p>
                            </div>
                            <div class="branch">
                              <p class="logo" v-if="item.resourceProviderLogo">
                                <img :src="item.resourceProviderLogo" alt="">
                              </p>
                              <p class="name">
                                {{item.branchShortName || item.branchName}}
                              </p>
                            </div>
                            <p class="teacherTag">
                                <template v-for="(el,ind) in transformList(item.labelNameList)">
                                    <span class="classHour" :key="ind">
                                    {{el}}
                                    </span>
                                </template>
                            </p>
                        </div>
                    </div>
                    <div class="line" v-if="token && !wdSubBranch"></div>
                    <div class="bottom" v-if="token && !wdSubBranch">
                        <div class="left">
                            <div v-if="item.isFree === 1">
                                <!-- <span class="isFree">免费</span> -->
                            </div>
                            <div v-else class="price-box">
                                <!-- <p class="price">售价：¥{{item.price}}</p>
                                <p class="commission">预计收益：<span>¥{{item.commission}}</span></p> -->
                            </div>
                        </div>
                        <div class="right" v-if="!wdSubBranch">
                            <p class="btn">推广</p>
                        </div>
                    </div>
                </div>
            </van-list>
            <div v-else class="empty">
              <img src="./../asset/images/empty-img.png" alt="">
              <p>暂无内容记录</p>
            </div>
        </div>
        
        <van-popup v-model:show="popupShow" @close="close" class="vocation-box" position="bottom" :style="{ height: '76%' }">
            <p class="close-icon" @click="close">
              <svg-icon icon-class="close"></svg-icon>
            </p>
            <div class="container" >
                <div class="vocationInfo">
                    <h2 class="name">{{vocationInfo.vocation_name}}</h2>
                    <p class="info">{{vocationInfo.vocation_intro}}</p>
                </div>
                <div class="work">
                    <div class="projectIntro-middle" v-if="workInfoList.length === 1 && workInfoList.length">
                        <div class="content">
                            <h2 class="name">{{workInfoList[0].work_name}} <span class="line"></span></h2>
                            <p class="work_intro">{{workInfoList[0].work_intro}} <span class="btn" v-if="workInfoList[0].work_url && !['整理收纳师','家务服务员','家庭照护员'].includes(workInfoList[0].work_name)" :class="{gray:!workInfoList[0].work_url}" @click="docPreview(workInfoList[0])" >查看详情</span></p>
                        </div>
                    </div>
                    <div class="swiper-wrap" v-else>
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div ref="swiperSlide"  class="swiper-slide" v-for="item in workInfoList" :key="item.id">
                                    <div class="content">
                                        <h2 class="name">{{item.work_name}} <span class="line"></span></h2>
                                        <p class="work_intro">{{item.work_intro}} <span class="btn" v-if="item.work_url && !['整理收纳师','家务服务员','家庭照护员'].includes(item.work_name)" :class="{gray:!item.work_url}" :data-item="JSON.stringify(item)" >查看详情</span></p>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="swiper-button-next">
                              <svg-icon icon-class="banner-right"></svg-icon>
                            </div>
                            <div class="swiper-button-prev">
                              <svg-icon icon-class="banner-left"></svg-icon>
                            </div>
                            
                            <!-- <div class="swiper-pagination" /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="button" @click="close">
                <p>已了解</p>
            </div>
        </van-popup>
        <van-popup v-model:show="popupShow2" @close="close2" class="vocation-box vocation-box2" position="bottom" :style="{ height: '76%' }">
            <p class="close-icon" @click="close2">
              <svg-icon icon-class="close"></svg-icon>
            </p>
            <div class="Vidpreview-container"  >
                <h2 class="name">{{work_name}}</h2>
                <div class="Vidpreview-box">
                    <div id="Vidpreview" style="width:100%;height:500px;"></div>
                </div>
            </div>
            <div class="button" @click="close2">
                <p>已了解</p>
            </div>
        </van-popup>

    </div>
</template>
<script>

import { teacherWorkTypeList,teacherIntro} from "./../../renshehome/common/data.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
    name:'projectIntro',
    data(){
        return{
            wdSubBranch:false,
            transLevel:{
                1:'01',
                2:'02',
                3:'03',
                4:'04',
                5:'05'
            },
            swiper:null,
            popupShow:false,
            popupShow2:false,
            loading:false,
            finished:false,
            workInfoList:[], // 工种 简介
            skillLevelIndex:0, // 等级
            workTypeIndex:0, // 工种分了下标
            teacherWorkTypeIndex:this.$route.query.code,
            currentTeacherIndex:0,
            teacherSkillLevelIndex:0,
            trainMethodIndex:0, // 培训方式
            vocationInfo:{}, // 职业简介
            work_name:'',
            teacherWorkTypeList:teacherWorkTypeList,
            teacherSkillLevelList:teacherWorkTypeList[0].workTypeList,// 技能等级列表
            skillLevelList:[
                {
                    value:0,
                    text:'技能等级',
                }
            ],// 技能等级列表
            trainMethodList:[
                {
                    value:0,
                    text:'培训方式',
                }
            ],// 培训方式列表
            workTypeList:[
                {
                    value:0,
                    text:'培训工种',
                }
            ],// 工种列表
            sortIndex:1, // 排序
            sortList:[
                {
                    value:1,
                    text:'智能排序'
                },
                {
                    value:2,
                    text:'最热'
                },
                {
                    value:3,
                    text:'佣金'
                }
            ],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 8
                },
                total: 0,
                totalPages: 0,
                currentSize: 0,
                schoolHour:0,// 课时数
            },
            goodsListParams:{
                branchId:'',// 机构id
                centerType:1,//1 服务商 2 渠道方
                name:'',
                sort:'',//排序规则 1 智能排序；2 最热排序；3 佣金
                fangshi:'',// 培训方式
                gongzhong:'',//工种分类
                jineng:'',//等级
                zhiye:'',// 职业分类
            },
            goodsList:[],// 商品列表
            isGoodsList:true,
            levelAll:[],
        }
    },
    computed: {
        token(){
            return localStorage.getItem('token')
        }
	},
    created(){
        this.goodsListParams.branchId =  localStorage.getItem('branchId') || "";
        this.goodsListParams.zhiye = this.$route.query.code || '4-10-01-06';
        this.goodsListParams.gongzhong = this.$route.query.gongzhong;
        if(localStorage.getItem('token')){
            this.currBranchInfo()
        }
    },
    mounted(){
        this.goodsListParams.sort = this.token ? 1 : '';
        if(this.$route.query.spec!='teacher'){
            this.getfilterInfo();
            this.getVocationByCode();
            this.goodsListParams.teacherFlag = 2;
        }else{
            this.goodsListParams.teacherFlag = 1;
            this.vocationInfo = {...teacherIntro}
            this.getData();
        }
    },
    methods:{
        // 是否为伟东下的渠道方
        currBranchInfo(){
            this.$api.rensheUsercenter.currBranchInfo().then(res=>{
                if(res.data){
                    this.wdSubBranch = res.data.wdSubBranch;
                }
            })
        },
        // 师资的只显示师资标签，其他工种显示所有标签
        transformList(list){
            if(this.$route.query.spec=='teacher' && list.length>1){
                let tempList = list.filter(item=>{
                    if(item.indexOf('师资培训')>-1){
                        return item
                    }
                })
                return tempList || []
            }else{
                return list
            }
        },
        backHome(){
            this.$router.push('/home')
        },
        /** 查看职业工种弹窗 */
        checkVocation(){
            this.popupShow = true;

            this.$nextTick(() => {
                if(this.workInfoList.length > 1){
                    this.initSwiper();
                }
            })
            
        },
        /** 初始化轮播 */
        initSwiper() {
            // let selfthis = this;
            this.swiper = new Swiper(".swiper-container", {
                loop: true,
                autoplay: false,
                spaceBetween: 15,
                observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, // 修改swiper的父元素时，自动初始化swiper
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                on:{
                    click:(event) => {
                        console.log(event.target.className,'event.target.className')
                        if(event.target.className === 'btn'){
                            this.docPreview(JSON.parse(event.target.dataset.item))
                        }
                    }
                }
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
            });
        },
        close(){
            console.log('close')
            this.popupShow = false;
        },
        close2(){
            this.work_name = "";
            let Vidpreview=document.querySelector('.web-office-default-container')
            if(Vidpreview){
                document.body.removeChild(Vidpreview)
            }
            this.popupShow2 = false
        },
        onClickLeft(){
            this.$router.go(-1);
        },
        /** 商品筛选信息 */
        async getfilterInfo(){
            const params = {
                branchId:this.goodsListParams.branchId,
                centerType:'1'
            }
             if(this.$route.query.code=='4-14-02-05'){
                params.tagFlag = 1; //老年人能力评估等级：（技师和高级）
            }
           await this.$api.projectIntro.getfilterInfo({params}).then(res => {
                if(res.data){
                    this.levelAll = res.data.level || [];
                    let trainMethodList = res.data.label.map(item => { return { ...item, value: item.id, text: item.name} })
                    this.trainMethodList.push(...trainMethodList)
                    res.data.category.forEach(item => {
                        if(item.vocationCode === this.$route.query.code){
                            let workTypeList = item.subVocations.map(item2 => { return { ...item2, value: item2.vocationCode, text: item2.vocationName} })
                            this.workTypeList.push(...workTypeList)
                        }
                    })
                    if(this.workTypeIndex === 0){
                      let skillLevelList = res.data.level.map(item2 => { return { ...item2, value: item2.vocationCode, text: item2.vocationName} })
                      this.skillLevelList.push(...skillLevelList)
                    }
                    
                }
                if(!this.$route.query.gongzhong && this.$route.query.spec!=='teacher'){
                    this.goodsListParams.gongzhong = this.workTypeList[1].vocationCode;
                    this.workTypeIndex = this.workTypeList[1].vocationCode;
                }else if(this.$route.query.gongzhong){
                    this.workTypeIndex = this.$route.query.gongzhong
                }
                this.getData();
            })
        },
        /** 获取职业工种简介信息 */
        getVocationByCode(){
            const params = {
                vocation_code: this.$route.query.code || '4-10-01-06'
            }
            this.$api.projectIntro.getVocationByCode({params}).then(res => {
                if(res.data){
                    this.vocationInfo = res.data;
                    this.workInfoList = res.data.workList || [];
                }
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.getData();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getData();
        },
        /** 查看文档 */
        docPreview(item){
            if(!item.work_url){
                this.$toast('标准建设中');
                return false;
            }
            this.work_name = item.work_name;
            this.popupShow2 = true;
            const params = {
                resId: item.work_url
            }
            this.$api.projectIntro.getDocumentAccessToken({params}).then(res => {
                if (res.success) {
                    // 下边是安全才有的
                    this.$nextTick(() => {
                        // eslint-disable-next-line no-undef
                        let demo = aliyun.config({
                            mount: document.querySelector('#Vidpreview'),
                            url: res.data.previewURL, //设置文档查看URL地址。
                        })
                        // //设置AccessToken。
                        demo.setToken({ token: res.data.accessToken })
                    })
                } 
            })
           

        
        },
        /** 跳转商品详情页 */
        goGoodsDetail(item){
            if(this.wdSubBranch){
                this.$toast({
                    message: '无法进入学习',
                })
                return
            }
            this.$router.push({
                path:'/goodsDetail',
                query:{
                    goodsId:item.id,
                    goodsKind:item.goodsKind || 2,
                    fromType:this.token ? 3 : 1, // 登录后传3 不登陆传1
                }
            })
        },
        /** 技能等级 */
        skillLevelClick(value){
            this.skillLevelIndex = value;
            console.log(value,'value')
            this.goodsListParams.jineng = value || '';
            this.paging.params.pageNum = 1;
            this.getData();
        },
        /** 培训方式 */
        trainMethodClick(value){
            this.trainMethodIndex = value;
            this.goodsListParams.fangshi = value || '';
            this.paging.params.pageNum = 1;
            this.getData();
        },
        teacherWorkTypeClick(value){
            this.teacherWorkTypeIndex = value;
            this.paging.params.pageNum = 1;
            this.goodsListParams.zhiye = value;
            this.goodsListParams.gongzhong = 0,
            this.teacherSkillLevelIndex = 0;
            this.teacherWorkTypeList.filter((item,index)=>{
                this.currentTeacherIndex = item.value == value ? index : this.currentTeacherIndex
            })
            this.teacherSkillLevelList = teacherWorkTypeList[this.currentTeacherIndex].workTypeList;
            this.getData();
        },
        teacherSkillLevelClick(value){
            this.paging.params.pageNum = 1;
            this.teacherSkillLevelIndex = value;
            this.goodsListParams.gongzhong = value;
            this.getData();
        },
        /** 培训工种分类 */
        workTypeClick(value){
            this.workTypeIndex = value;
            console.log(value)
            this.skillLevelList = [{
                value:0,
                text:'技能等级',
            }];
            if(value == 0){
              let skillLevelList = this.levelAll.map(item2 => { return { ...item2, value: item2.vocationCode, text: item2.vocationName} })
              this.skillLevelIndex = 0;
              this.skillLevelList.push(...skillLevelList)
            }else {
                this.workTypeList.forEach(item => {
                  if(item.value === value){
                    if(item.subVocations){
                      let skillLevelList = item.subVocations.map(item2 => {return {...item2,value: item2.vocationCode, text: item2.vocationName}});
                      console.log(skillLevelList,'skillLevelList')
                      this.skillLevelIndex = 0;
                      this.skillLevelList.push(...skillLevelList);
                    }
                  }
                })
            }
            
            this.goodsListParams.gongzhong = value || "";
            this.goodsListParams.jineng = "";
            this.paging.params.pageNum = 1;
            this.getData();
        },
        /** 排序点击 */
        sortClick(value){
            this.sortIndex = value;
            this.goodsListParams.sort = value;
            this.paging.params.pageNum = 1;
            this.getData();
        },
        searchBtn(){
            this.paging.params.pageNum = 1;
            this.getData()
        },
        /**
         * 上拉加载
         */
        onLoad() {
            this.paging.params.pageNum = this.paging.params.pageNum*1 + 1
            this.getData('reachBottom')
        },
        /** 商品列表 */
        getData(type){
            let data = {
                ...this.goodsListParams,
            }
            data.gongzhong = data.gongzhong == 0?'':data.gongzhong;
            data.zhiye = data.zhiye == 0?'':data.zhiye;
            const params = {
                ...this.paging.params
            }
            if(data.gongzhong && [1,2,3,4,5].includes(data.jineng)){
                data.jineng =data.gongzhong+'-'+this.transLevel[data.jineng]
            }
            this.$api.projectIntro.getGoodsList({data,params}).then(res => {
                if(res.data){
                    
                    if (type === "reachBottom") {
                        this.goodsList.push(...res.data.list);
                    } else {
                        this.goodsList = res.data.list || [];
                        
                    }
                    if(this.goodsList.length){
                        this.isGoodsList = true;
                    }else{
                        this.isGoodsList = false;
                    }
                    this.paging.total = res.data.total;
                    this.paging.schoolHour = res.data.schoolHour;
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    this.finished = res.data.isLastPage;
                }else {
                    this.isGoodsList = false;
                }
            }).catch(() =>{
                this.isGoodsList = false;
            })
        },
    }
}
</script>
<style lang="stylus" src="../asset/css/index.styl" scoped>

</style>
